<script>
import Vue from 'vue'
import { ResourceListField, ResourceListAction, ListFilterSelect } from '@sigmacloud/sigma-client/dist/components/core/resource_list'

export default Vue.extend({
    props: {
        instanceName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            poId: '',
        }
    },
    computed: {
        listFields() {
            return [
                new ResourceListField('Transaction #', 'id'),
                new ResourceListField('Number', 'number'),
                new ResourceListField('Vendor', 'vendor.alias_name'),
                new ResourceListField('Bank', 'batch.bank.name'),
                new ResourceListField('Amount', 'amount_decimal'),
            ]
        },
        listFilters() {
            return [
                new ListFilterSelect('Status', 'status', {
                    value: ['CLOSED', 'POSTED'],
                    choices: ['POSTED', 'OPEN', 'CLOSED', 'DRAFT'],
                }),
            ]
        },
        filters() {
            return {
                ListFilterSelect,
            }
        },
        listActions() {
            return [
                new ResourceListAction('Reload Selected', {
                    async handler(resources) {
                        return await Promise.all(resources.map((resource) => resource.update()))
                    },
                }),
            ]
        },
    },
    beforeMount() {
        window.$c = this
    },
})
</script>

<template>
    <div class="home">
        <resource-list
            ref="list"
            :resource-class="$sigma.resources.AccountsPayableResource"
            :resolve-attributes="['batch.bank.currency.code']"
            :fields="listFields"
            :filters="listFilters"
            :actions="listActions"
            title="Accounts Payable">
            <template #value-amount_decimal="data">
                <resource-format
                    display-template="amount_decimal"
                    :resource="data.resource"
                    :currency="data.resource.get('batch.bank.currency.code')"
                    :append-text="' ' + data.resource.get('batch.bank.currency.code')" />
            </template>
            <template #value-number="data">
                <resource-format
                    display-template="number"
                    :resource="data.resource"
                    linked
                    @click="
                        $router.push({
                            name: 'apEdit',
                            params: { instanceName, id: data.resource.id },
                        })
                    " />
            </template>
            <template #header-amount_decimal>
                <div class="text-right">Amount</div>
            </template>
        </resource-list>

        <b-card no-body>
            <b-tabs pills card vertical>
                <b-tab title="Drafts" active>
                    <b-card-text>
                        <resource-list
                            :resource-class="$sigma.resources.PurchaseOrderResource"
                            :fields="listFields"
                            :filters="filters.ListFilterSelect.raw({ status: 'DRAFT' })"
                            title="Purchase Order Drafts">
                        </resource-list>
                    </b-card-text>
                </b-tab>

                <b-tab title="Posted Transactions">
                    <b-card-text>
                        <resource-list
                            :resource-class="$sigma.resources.PurchaseOrderResource"
                            :fields="listFields"
                            :filters="filters.ListFilterSelect.raw({ status__in: 'POSTED,CLOSED' })"
                            title="Purchase Order Posted Transactions">
                        </resource-list>
                    </b-card-text>
                </b-tab>

                <b-tab title="Open Transactions">
                    <b-card-text>
                        <resource-list
                            :resource-class="$sigma.resources.PurchaseOrderResource"
                            :fields="listFields"
                            :filters="filters.ListFilterSelect.raw({ status: 'OPEN' })"
                            title="Purchase Order Open Transactions">
                        </resource-list>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<style scoped>
.resource-attr.currency {
    font-family: 'Courier New', sans-serif;
    font-weight: bold;
}
</style>
